<template>
  <div class="view-wrap">
    <el-form :model="searchForm" class="F">
      <el-form-item label="业务日期:" style="width: 230px">
        <el-input
          v-model="saleInfo.entryDate"
          disabled
          style="width: 130px"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="单据编号:"
        style="width: 230px; margin-right: 15px"
        label-width="80px"
      >
        <el-input
          type="text"
          placeholder=""
          v-model="saleInfo.no"
          disabled
          style="width: 160px"
        ></el-input>
      </el-form-item>
      <el-form-item label="出库仓库:" style="width: 230px">
        <el-input
          v-model="saleInfo.wmsName"
          disabled
          style="width: 130px"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="客户:"
        style="width: 200px; margin-right: 15px"
        label-width="60px"
      >
        <el-input
          v-model="saleInfo.customerName"
          disabled
          style="width: 130px"
        ></el-input>
      </el-form-item>

      <!-- <el-form-item label="当前欠款:" style="width: 140px">
        <span style="color: #f00">{{
          payment ? (payment / 100).toFixed(2) : "0.00"
        }}</span
        >元
      </el-form-item> -->
      <el-form-item
        label="处方:"
        style="width: 250px; margin-right: 15px"
        label-width="60px"
      >
        <div style="position: relative">
          <el-input v-model="checkPrescribeName" disabled>
            <el-button slot="append" type="primary" @click="prescribeClick"
              >查看</el-button
            >
          </el-input>

          <!-- <span
            @click="prescribeClick"
            style="position: absolute; right: 10px; top: -3px; cursor: pointer"
            ></span
          > -->
        </div>
      </el-form-item>

      <el-form-item label="制单人:" style="width: 230px">
        <el-input
          type="text"
          placeholder=""
          v-model="saleInfo.handlerName"
          disabled
          style="width: 160px"
        ></el-input>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      border
      show-summary
      :summary-method="getSummaries"
      style="width: 100%"
    >
      <el-table-column label="序号" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="商品名称"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="code"
        label="商品编号"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="unitName"
        label="单位"
        align="center"
      ></el-table-column>
      <el-table-column prop="num" label="数量" align="center"></el-table-column>
      <el-table-column prop="price" label="单价" align="center">
        <template slot-scope="scope">
          {{ scope.row.price ? scope.row.price.toFixed(2) : "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="totalPrice" label="金额" align="center">
        <template slot-scope="scope">
          {{ scope.row.totalPrice ? scope.row.totalPrice.toFixed(2) : "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="discount" label="折扣率(%)" align="center">
        <template slot-scope="scope">
          {{ scope.row.discount ? scope.row.discount + "%" : "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="youhui" label="折后优惠" align="center">
        <template slot-scope="scope">
          {{ scope.row.youhui }}
        </template>
      </el-table-column>
      <el-table-column prop="discountPrice" label="折后单价" align="center">
        <template slot-scope="scope">
          {{ scope.row.discountPrice ? scope.row.discountPrice : "-" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="discountTotalPrice"
        label="折后金额"
        align="center"
      >
        <template slot-scope="scope">
          {{
            scope.row.discountTotalPrice ? scope.row.discountTotalPrice : "-"
          }}
        </template>
      </el-table-column>

      <!-- <el-table-column prop="isGifts" label="赠品" align="center">
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row.isGifts" disabled :true-label="1" :false-label="0"></el-checkbox>
        </template>
      </el-table-column> -->
      <el-table-column
        prop="regCode"
        label="备注"
        align="center"
      ></el-table-column>
    </el-table>
    <div class="footer" style="width: 82%">
      <el-row>
        <el-col :span="2">备注：</el-col>
        <el-col :span="22">
          {{ saleInfo.remark }}
        </el-col>
      </el-row>
      <div class="">
        <el-row>
          <el-col :span="6">
            合计金额：￥{{ (saleInfo.price / 100).toFixed(2) }}
          </el-col>
          <el-col :span="6">
            整单优惠：￥{{ (saleInfo.preferentialPrice / 100).toFixed(2) }}
          </el-col>
          <el-col :span="6">
            应收金额：<span style="color: red">
              ￥{{ (saleInfo.handlePrice / 100).toFixed(2) }}</span
            >
          </el-col>
          <el-col :span="6">
            剩余欠款：<span style="color: red">
              ￥{{
                (
                  saleInfo.handlePrice / 100 -
                  saleInfo.prepaidPrice / 100
                ).toFixed(2)
              }}</span
            >
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            已收金额：<span style="color: blue"
              >￥{{ (saleInfo.prepaidPrice / 100).toFixed(2) }}</span
            >({{
              saleInfo.accountList
                .map((v) => {
                  return v.accountName + ":" + (v.price / 100).toFixed(2);
                })
                .join(";")
            }})
          </el-col>
        </el-row>
        <!-- <div class="mr-15"></div>
        <div class="mr-15">
          整单优惠：{{ saleInfo.preferentialPrice / 100 }}
        </div>
        <div class="mr-15">应收金额：{{ saleInfo.handlePrice / 100 }}</div>
        <div class="mr-15">本次已收金额：{{ saleInfo.prepaidPrice / 100 }}</div>
        <div class="mr-15">付款账户：{{ saleInfo.accountName }}</div>
        <div>
          剩余欠款：<span style="color: red">{{
            (saleInfo.handlePrice / 100 - saleInfo.prepaidPrice / 100).toFixed(
              2
            )
          }}</span>
        </div> -->
      </div>
      <div class="FSB">
        <div></div>
        <div>
          <el-button type="primary" size="mini" @click="backList"
            >返回列表</el-button
          >
        </div>
      </div>
    </div>
    <el-dialog title="查看处方" :visible.sync="prescribeIsShow" width="40%">
      <el-table
        v-loading="loading"
        style="margin: 0px 10px; width: 97%"
        border
        ref="multipleTable"
        :data="prescriptionList"
        tooltip-effect="dark"
        row-key="id"
      >
        <el-table-column align="center" width="180" label="处方名称" prop="name"
          ><template slot-scope="scope"
            ><div style="text-align: left">
              {{ scope.row.name ? scope.row.name : "-" }}
              {{ scope.row.type == "1" ? "（临时）" : "" }}
            </div></template
          ></el-table-column
        >
        <el-table-column align="center" label="处方说明" prop="description"
          ><template slot-scope="scope"
            ><div style="text-align: left">
              {{ scope.row.description ? scope.row.description : "-" }}
            </div></template
          ></el-table-column
        >
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { getSaleDetails } from "@/api/sale";
import { GetWmsList } from "@/api/warehouse";
export default {
  data() {
    return {
      saleInfo: {},
      id: "",
      tableData: [],
      wmsList: [],
      checkPrescribeName: "",
      prescriptionList: [], // 已选择处方列表
      prescribeIsShow: false,
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getDetails();
  },
  methods: {
    async getDetails() {
      let res = await getSaleDetails({ id: this.id });
      if (res.code == 1) {
        this.saleInfo = res.data;
        this.prescriptionList = res.data.prescriptionList;
        this.checkPrescribeName = res.data.prescriptionList
          .map((v) => v.name)
          .join(",");

        this.tableData = res.data.productList;

        this.tableData.forEach((item) => {
          item.price = item.price / 100;
          item.discountPrice = item.discountPrice / 100;
          item.discountTotalPrice = item.discountTotalPrice / 100;
          item.totalPrice = item.totalPrice / 100;
          item.youhui = (item.totalPrice - item.discountTotalPrice).toFixed(2);
        });
      }
    },
    //获取仓库
    async GetWmsList() {
      let res = await GetWmsList();
      if (res.code == 1) {
        this.wmsList = res.data.records;
      }
    },
    backList() {
      this.$router.push({
        path: "/sale/retailOutletList/saleOutList",
      });
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "总计";
        } else if (index === 4 || index === 6 || index === 10) {
          const values = data.map((item) => Number(item[column.property]));

          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
          } else {
            sums[index] = "N/A";
          }
        } else {
          sums[index] = "";
        }
      });
      return sums;
    },
    prescribeClick() {
      this.prescribeIsShow = true;
    },
  },
};
</script>

<style scoped lang="scss">
.F {
  display: flex;
}
.FSB {
  display: flex;
  justify-content: space-between;
}
.footer {
  position: fixed;
  bottom: 10px;
  .totalNum {
    line-height: 40px;
    .mr-15 {
      margin-right: 15px;
    }
  }
}
/deep/ .el-input.is-disabled .el-input__inner {
  color: #333;
}
/deep/ .el-input-group__append button.el-button,
.el-input-group__append div.el-select .el-input__inner,
.el-input-group__append div.el-select:hover .el-input__inner,
.el-input-group__prepend button.el-button,
.el-input-group__prepend div.el-select .el-input__inner,
.el-input-group__prepend div.el-select:hover .el-input__inner {
  color: #fff;
  background-color: #409eff;
  border-color: #409eff;
}
</style>
